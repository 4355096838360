//import logo from './../logo.svg';
import './App.css';
import CustomCursor from './CustomCursor';
import Header from './Header';
import MainSlider from './MainSlider';
import Request from './Request';
import About from './About';
import Brand from './Brand';
import WeFly from './WeFly';
import Charters from './Charters';
import Benefits from './Benefits';
import Counter from './Counter';
import Destination from './Destination';
import WeDo from './WeDo';
import Blog from './Blog';
import Catalogue from './Catalogue';
import Footer from './Footer';
import MobileNav from './MobileNav';
import SearchPopup from './SearchPopup';

function App() {
  return (
  <>
    <CustomCursor/>
    <div className="page-wrapper">
   	<Header/>
    	<MainSlider/>
    	<Request/>
    	<About/>
    	<Brand/>
    	<WeFly/>
    	<Charters/>
    	<Benefits/>
    	<Counter/>
    	<Destination/>
    	<WeDo/>
    	<Blog/>
    	<Catalogue/>
    	<Footer/>
    </div>
    <MobileNav/>
    <SearchPopup/>
   </>
  );
}

export default App;
