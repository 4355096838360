const Charters = () => {
	return (
		<>
		  <section className="feature-three">
		    <div className="feature-three__shape-1" style={{backgroundImage: "url(./images/shapes/feature-three-shape-1.png)"}}></div>
		    <div className="container">
		      <div className="section-title text-center">
		        <span className="section-title__tagline">OUR Features</span>
		        <h2 className="section-title__title">Developed with
                        <br/> the Best Quality Materials</h2>
                      </div>
                      <div className="feature-three__inner">
                        <div className="row">
                          <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp animated" data-wow-delay="100ms"
                            style={{visibility: "visible", animationDelay: "100ms", animationName: "fadeInUp"}}>
                            <div className="feature-three__single">
                              <div className="feature-three__icon">
                                <span className="icon-diamond-1"></span>
                              </div>
                              <h4 className="feature-three__title"><a href="about.html">Luxury and <br/> comfort</a></h4>
                                <p className="feature-three__text">1 Bed Luxuries Flats, Huge Balcony
                                    Space, Budget Friendly </p><br/>
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp animated" data-wow-delay="200ms"
                            style={{visibility: "visible", animationDelay: "200ms", animationName: "fadeInUp"}}>
                              <div className="feature-three__single">
                                <div className="feature-three__icon">
                                  <span className="icon-safe-flight"></span>
                                </div>
                                <h4 className="feature-three__title"><a href="about.html">Safe and
                                        <br/> secure</a></h4>
                                <p className="feature-three__text">Latest Technology Lifts with Maintenance
                                    24 × 7 Security & CCTV Camera, Fire Safety
                                </p>
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp animated" data-wow-delay="300ms"
                            style={{visibility: "visible", animationDelay: "300ms", animationName: "fadeInUp"}}>
                              <div className="feature-three__single">
                                <div className="feature-three__icon">
                                  <span className="icon-water"><img src={"./images/amenities.png"} style={{maxWidth: "70px", marginTop: "-20px"}}/></span>
                                </div>
                                <h4 className="feature-three__title"><a href="about.html"> Water<br/>Facilities</a></h4>
                                <p className="feature-three__text">24 × 7 Water Facility, Standard Company Solar Water
                                    System, RCC Water Tank,Massive Parking
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
		</>
	);
}

export default Charters;
