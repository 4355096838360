const About = () => {
	return (
		<>
		  <section className="about-three" id="about">
		    <div className="container">
		      <div className="row">
		        <div className="col-xl-6">
		          <div className="about-three__left">
		            <div className="about-three__img-box wow slideInLeft" data-wow-delay="100ms" data-wow-duration="2500ms">
		              <div className="about-three__img">
		                <img src={"./images/abt.jpg"} alt=""/>
		              </div>
		              <div className="about-three__experience">
		                <div className="about-three__curved-circle">
		                  <div className="curved-circle">
		                    <span className="curved-circle--item" data-circle-options='{
                                                "radius": 100
                                            }'>
                                                22 Years Experience
		                    </span>
		                  </div>
		                </div>
		                <div className="about-three__icon-box">
		                  <div className="about-three__icon">
		                    <img src={"./images/favicons/favicon.png"} alt="" style={{maxWidth: "70px"}}/>
		                  </div>
		                </div>
		              </div>
		            </div>
		          </div>
		        </div>
		        <div className="col-xl-6">
		          <div className="about-three__right">
		            <div className="section-title text-left">
		              <span className="section-title__tagline">About us</span>
		              <h2 className="section-title__title">Gurukrupa Developers, the building experts</h2>
		            </div>
		            <p className="about-three__text-one">Gurukrupa Developers is one of the leading construction
                                companies in Rajkot, Gujarat. Started in 2000, Gurukrupa Developers has contributed a
                                prominent addition to the construction and real estate industries.</p>
		            <p className="about-three__text-two"> We are well-known for
                                our Residential and Commercial buildings. We are committed to satisfying our customers
                                with quality services.
                            </p>
		            <p>From the lowest budget in Residential and Commercial projects, we deliver the highest
                                facilities with the best quality materials and the latest technology used in
                                construction. Some of our renowned projects are Atmiya Projects, Shyam Projects, and
                                more.</p>
		          </div>
		        </div>
		      </div>
		    </div>
		  </section>
		</>
	);
}

export default About;
