const WeDo = () => {
	return (
		<>
		  <section className="we-do">
            <div className="we-do__wrap">
                <div className="we-do__left">
                    <div className="we-do__bg" style={{backgroundImage: "url(./images/backgrounds/we-do-bg.jpg)"}}></div>
                    <div className="we-do__real-time">
                        <div className="we-do__real-time-shape">
                            <img src={"./images/shapes/we-do-real-time-shape.png"} alt=""/>
                        </div>
                        <div className="we-do__real-time-icon">
                            <span className="icon"><img src={"./images/quality.png"} style={{maxWidth: "60px"}} alt="" /></span>
                        </div>
                        <div className="we-do__real-time-content-box">
                            <h4 className="we-do__real-time-content">The best Quality<br/> Materials</h4>
                        </div>
                    </div>
                </div>
                <div className="we-do__right">
                    <div className="we-do__shape-1 float-bob-y">
                        <img src={"./images/shapes/we-do-shape-1.png"} alt=""/>
                    </div>
                    <div className="we-do__content">
                        <div className="section-title text-left">
                            <span className="section-title__tagline">Company Strategy</span>
                            <h2 className="section-title__title">Mission & Vision</h2>
                        </div>
                        <div className="we-do__bottom">
                            <ul className="list-unstyled we-do__points">
                                <li>
                                    <div className="icon">
                                        <span className="icon-online-bookin"><i className="fa fa-bullseye"></i></span>
                                    </div>
                                    <div className="content">
                                        <h4>Our Mission</h4>
                                        <p>At Gurukrupa Developers, our mission is to deliver quality products with
                                            inimitable satisfaction to our clients at the lowest price in the city.
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="icon">
                                        <span className="icon"><i className='far fa-eye'></i></span>
                                    </div>
                                    <div className="content">
                                        <h4>Our Vision</h4>
                                        <p>At Gurukrupa Developers, our vision is to accommodate a high and mighty
                                            contribution in the construction and real estate industries by distributing
                                            our products at affordable prices.</p>
                                        <p>Choose the right home for your lifestyle. And luxuries office at a prime
                                            location with the lowest budget in the city with Gurukrupa Developers. We
                                            deliver world-className infrastructure in your budget. Check out our must-see
                                            projects.</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
		</>
	);
}

export default WeDo;
