
const Header = () => {
  return (
  <>
        <header className="main-header-three">
            <div className="main-header-three__top">
                <div className="main-header-three__top-inner">
                    <div className="main-header-three__top-left">
                        <ul className="list-unstyled main-header-three__contact-list">
                            <li>
                                <div className="icon">
                                    <i className="fas fa-map-marker-alt"></i>
                                </div>
                                <div className="text">
                                    <p>RK park, 150 fit ring road, Rajkot-360004 </p>
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <i className="fas fa-envelope"></i>
                                </div>
                                <div className="text">
                                    <p><a href="mailto:gurukrupabuildersrajkot@gmail.com">gurukrupabuildersrajkot@gmail.com</a>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className="main-header-three__top-right">
                        <div className="main-header-three__social">
                            <a href="https://www.facebook.com/Gurukrupadevelopersrajkot/" target="_blank" className="fab fa-facebook-square"></a>
                            <a href="https://www.instagram.com/gurukrupa__builders/" target="_blank" className="fab fa-instagram"></a>
                        </div>
                    </div>
                </div>
            </div>
            <nav className="main-menu main-menu-three">
                <div className="main-menu-three__wrapper">
                    <div className="main-menu-three__wrapper-inner">
                        <div className="main-menu-three__left">
                            <div className="main-menu-three__logo">
                                <a href="index.html"><img src={"./images/resources/logo-2.png"} alt="" style={{maxWidth: "200px"}} /></a>
                            </div>
                        </div>
                        <div className="main-menu-three__main-menu-box">
                            <a href="#" className="mobile-nav__toggler"><i className="fa fa-bars"></i></a>
                            <ul className="main-menu__list one-page-scroll-menu">
                                <li className="dropdown current megamenu scrollToLink">
                                    <a href="#home">Home </a>
                                </li>
                                <li className="scrollToLink">
                                    <a href="#about">About</a>
                                </li>
                                <li className="scrollToLink">
                                    <a href="#services">Project</a>
                                </li>
                                <li className="scrollToLink">
                                    <a href="#benefits">Benefits</a>
                                </li>
                                <li className="scrollToLink">
                                    <a href="#news">Contact</a>
                                </li>
                            </ul>
                        </div>                        
                    </div>
                </div>
            </nav>
        </header>

        <div className="stricky-header stricked-menu main-menu main-menu-three">
            <div className="sticky-header__content"></div>
        </div>
    
    
    </>
  );
}

export default Header;
