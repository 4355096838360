
const CustomCursor = () => {
  return (
  <>
    <div className="custom-cursor__cursor"></div>
    <div className="custom-cursor__cursor-two"></div>
    <div className="preloader">
        <div className="preloader__image"></div>
    </div>
    
    
    </>
  );
}

export default CustomCursor;
