
const Request = () => {
  return (
  <>
       <section className="request">
            <div className="request__shape-1 float-bob-x">
                <img src={"./images/shapes/request-shape-1.png"} alt=""/>
            </div>
            <div className="request__shape-2 float-bob-x">
                <img src={"./images/shapes/request-shape-2.png"} alt=""/>
            </div>
            <div className="container">
                <div className="request__top">
                    <form className="request__form contact-form-validated" noValidate>

                        <ul className="list-unstyled request__input-list clearfix">
                            <li>
                                <div className="request__input-box">
                                    <p><img src={"./images/budget.png"} style={{maxWidth: "50px"}} alt=""/><br/><br/> <b>Lowest
                                            Budget</b> </p>
                                </div>
                            </li>
                            <li>
                                <div className="request__input-box">
                                    <p><img src={"./images/amenities.png"} style={{maxWidth: "50px"}} alt=""/><br/><br/>
                                        <b>Highest Facilities</b>
                                    </p>
                                </div>
                            </li>
                            <li>
                                <div className="request__input-box">
                                    <p><img src={"./images/quality.png"} style={{maxWidth: "50px"}} alt=""/><br/><br/> <b> Best
                                            Quality</b></p>
                                </div>
                            </li>
                            <li>
                                <div className="request__input-box">
                                    <p><img src={"./images/innovation.png"} style={{maxWidth: "50px"}} alt=""/><br/><br/><b>Latest Technology</b> </p>
                                </div>
                            </li>
                            <li></li>

                        </ul>

                    </form>
                </div>
            </div>
        </section>
        
       </>
  );
}

export default Request;
