
const MainSlider = () => {
  return (
  <>
        <section className="main-slider-three clearfix" id="home">
            <div className="swiper-container thm-swiper__slider" data-swiper-options='{
                "slidesPerView": 1,
                "allowTouchMove": false,
                "loop": false,
                "effect": "fade",
                "pagination": {
                    "el": "#main-slider-pagination",
                    "type": "bullets",
                    "clickable": true
                },
                "navigation": {
                    "nextEl": "#main-slider__swiper-button-next",
                    "prevEl": "#main-slider__swiper-button-prev"
                }}'>
                <div className="swiper-wrapper">

                    <div className="swiper-slide">
                        <div className="image-layer-three" style={{backgroundImage: "url(./images/backgrounds/cloud-1.png)"}}></div>
                        <div className="main-slider-three__img">
                            <img src={"./images/resources/main-slider-three-img-1.png"} alt="" className="float-bob-y" />
                        </div>
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6">
                                    <div className="main-slider-three__content">
                                        <p className="main-slider-three__sub-title">We make sure</p>
                                        <h2 className="main-slider-three__title">We are the Best   <br/> Construction Company</h2>
                                       </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="swiper-slide">
                        <div className="image-layer-three float-bob-x" style={{backgroundImage: "url(./images/backgrounds/main-slider-3-1.png)"}}></div>
                        <div className="main-slider-three__img">
                            <img src={"./images/resources/main-slider-three-img-1.png"} alt="" className="float-bob-y"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       </>
  );
}

export default MainSlider;
