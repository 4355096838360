const MobileNav = () => {
	return (
		<div className="mobile-nav__wrapper">
        <div className="mobile-nav__overlay mobile-nav__toggler"></div>
        <div className="mobile-nav__content">
            <span className="mobile-nav__close mobile-nav__toggler"><i className="fa fa-times"></i></span>

            <div className="logo-box">
                <a href="index.html" aria-label="logo image"><img src={"./images/favicons/logo-w.png"} width="143" alt="" /></a>
            </div>
            <div className="mobile-nav__container"></div>
            <ul className="mobile-nav__contact list-unstyled">
                <li>
                    <i className="fa fa-envelope"></i>
                    <a href="mailto:gurukrupabuildersrajkot@gmail.com">gurukrupabuildersrajkot@gmail.com</a>
                </li>
                
            </ul>
            <div className="mobile-nav__top">
                <div className="mobile-nav__social">
                    <a href="https://www.facebook.com/Gurukrupadevelopersrajkot/" target="_blank" rel="noreferrer" className="fab fa-facebook-square"></a>
                    <a href="https://www.instagram.com/gurukrupa__builders/" target="_blank" rel="noreferrer" className="fab fa-instagram"></a>
                </div>
            </div>
        </div>
    </div>
	);
}

export default MobileNav;
