const Destination = () => {
	return (
		<>
		  <section className="destination-three">
            <div className="destination-three__shape-1 float-bob-y">
                <img src={"./images/shapes/destination-three-shape-1.png"} alt=""/>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-4">
                        <div className="destination-three__left">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">Delighting customers since 2000</span>
                                <h2 className="section-title__title">Completed projects</h2>
                            </div>
                            <p className="destination-three__text">Founded in 2000. Gurukrupa Builders is a front-runner in building homes that surpass every standard.</p>
                            
                        </div>
                    </div>
                    <div className="col-xl-8">
                        <div className="destination-three__right">
                            <div className="destination-three__carousel owl-carousel owl-theme thm-owl__carousel"
                                data-owl-options='{
                                "loop": true,
                                "autoplay": false,
                                "margin": 30,
                                "nav": true,
                                "dots": false,
                                "smartSpeed": 500,
                                "autoplayTimeout": 10000,
                                "navText": ["<span className=\"icon-left-arrow\"></span>","<span className=\"icon-right-arrow\"></span>"],
                                "responsive": {
                                    "0": {
                                        "items": 1
                                    },
                                    "768": {
                                        "items": 2
                                    },
                                    "992": {
                                        "items": 2
                                    },
                                    "1200": {
                                        "items": 2.93
                                    }
                                }
                            }'>
                                <div className="item">
                                    <div className="destination-one__single">
                                        <div className="destination-one__img-box">
                                            <div className="destination-one__img">
                                                <img src={"./images/building.jpg"} alt=""/>
                                            </div>
                                            <div className="destination-one__content">
                                                <h3 className="destination-one__title"><a href="destination-details.html">Shyam Sanidhya</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="destination-one__single">
                                        <div className="destination-one__img-box">
                                            <div className="destination-one__img">
                                                <img src={"./images/building.jpg"} alt=""/>
                                            </div>
                                            <div className="destination-one__content">
                                                <h3 className="destination-one__title"><a href="destination-details.html">Syam Arcard</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="destination-one__single">
                                        <div className="destination-one__img-box">
                                            <div className="destination-one__img">
                                                <img src={"./images/building.jpg"} alt=""/>
                                            </div>
                                            <div className="destination-one__content">
                                                <h3 className="destination-one__title"><a href="destination-details.html">Syam Builders</a></h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
		</>
	);
}

export default Destination;
