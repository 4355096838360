const Counter = () => {
	return (
		<>
		  <section className="counter-two">
            <div className="counter-two__shape-1" style={{backgroundImage: "url(./images/shapes/counter-two-shape-1.png)"}}></div>
            <div className="container">
                <ul className="list-unstyled counter-two__list">
                    <li className="counter-two__single wow fadeInUp" data-wow-delay="100ms">
                        <div className="counter-two__count-box count-box">
                            <h3 className="count-text" data-stop="700" data-speed="1500">00</h3>
                        </div>
                        <p className="counter-two__text">Happy Customers</p>
                    </li>
                    <li className="counter-two__single wow fadeInUp" data-wow-delay="200ms">
                        <div className="counter-two__count-box count-box">
                            <h3 className="count-text" data-stop="25" data-speed="1500">00</h3>
                        </div>
                        <p className="counter-two__text">Commercial Units Completed</p>
                    </li>
                    <li className="counter-two__single wow fadeInUp" data-wow-delay="300ms">
                        <div className="counter-two__count-box count-box">
                            <h3 className="count-text" data-stop="138" data-speed="1500">00</h3>
                        </div>
                        <p className="counter-two__text">Residential Units Completed</p>
                    </li>
                    <li className="counter-two__single wow fadeInUp" data-wow-delay="400ms">
                        <div className="counter-two__count-box count-box">
                            <h3 className="count-text" data-stop="280" data-speed="1500">00</h3>
                        </div>
                        <p className="counter-two__text">Total Projects Completed</p>
                    </li>
                </ul>
            </div>
        </section>

		</>
	);
}

export default Counter;
