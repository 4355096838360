const Brand = () => {
	return (
		<>
		  <section className="brand-two">
		    <div className="container">
		      <div className="brand-two__title"></div>
		      <div className="thm-swiper__slider swiper-container" data-swiper-options='{"spaceBetween": 100,
		    	"slidesPerView": 5,
		    	"loop": true,
		    	"navigation": { "nextEl": "#brand-one__swiper-button-next", "prevEl": "#brand-one__swiper-button-prev" },
		    	"autoplay": { "delay": 5000 },
		    	"breakpoints": {
		    		"0": { "spaceBetween": 30, "slidesPerView": 2 },
		    		"375": { "spaceBetween": 30, "slidesPerView": 2 },
		    		"575": { "spaceBetween": 30, "slidesPerView": 3 },
		    		"767": { "spaceBetween": 50, "slidesPerView": 4 },
		    		"991": { "spaceBetween": 50, "slidesPerView": 5 },
		    		"1199": { "spaceBetween": 100, "slidesPerView": 5 }
		      }}'>
		        <div className="swiper-wrapper">
		          <div className="swiper-slide">
		        	<img src={"./images/syam-logo.png"} alt=""/>
		          </div>
		          <div className="swiper-slide">
		        	<img src={"./images/syam-logo.png"} alt=""/>
		          </div>
		          <div className="swiper-slide">
		        	<img src={"./images/syam-logo.png"} alt=""/>
		          </div>
		          <div className="swiper-slide">
		        	<img src={"./images/syam-logo.png"} alt=""/>
		          </div>
		          <div className="swiper-slide">
		        	<img src={"./images/syam-logo.png"} alt=""/>
		          </div>
		          <div className="swiper-slide">
		        	<img src={"./images/syam-logo.png"} alt=""/>
		          </div>
		          <div className="swiper-slide">
		        	<img src={"./images/syam-logo.png"} alt=""/>
		          </div>
		          <div className="swiper-slide">
		        	<img src={"./images/syam-logo.png"} alt=""/>
		          </div>
		          <div className="swiper-slide">
		        	<img src={"./images/syam-logo.png"} alt=""/>
		          </div>
		          <div className="swiper-slide">
		        	<img src={"./images/syam-logo.png"} alt=""/>
		          </div>
		        </div>
		      </div>
		      <div className="brand-two__nav">
		       <div className="swiper-button-prev" id="brand-one__swiper-button-next">
		          <i className="fas fa-angle-left"></i>
		        </div>
		        <div className="swiper-button-next" id="brand-one__swiper-button-prev">
		          <i className="fas fa-angle-right"></i>
		        </div>
		      </div>
		    </div>
		  </section>
		</>
	);
}

export default Brand;
