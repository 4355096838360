const WeFly = () => {
	return (
		<>
		  <section className="we-fly" id="services">
		    <div className="we-fly-bg-box">
		      <div className="we-fly__bg" style={{backgroundImage: "url(./images/syam-d.png)"}}></div>
		    </div>
		    <div className="container">
		      <img src={"./images/we-fly-badge.png"} alt="" className="we-fly__badge"/>
		      <div className="row">
		        <div className="col-xl-7 col-lg-8">
		          <div className="we-fly__left">
		            <h3 className="we-fly__title count-box">Shyam Sanidhya Project</h3>
		            <p className="we-fly__text">Experience the lavish lifestyle with our new Shyam Sanidhya
                                Project. We provide the luxurious facility flats in your budget at a prime location in
                                Rajkot, Gujarat. We deliver the right home for your lifestyle.
		            </p>
		          </div>
		        </div>
		      </div>
		    </div>
		  </section>
		</>
	);
}

export default WeFly;
