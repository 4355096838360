const Benefits = () => {
	return (
		<>
		  <section className="benefits-one" id="benefits">
            <div className="benefits-one__shape-1 float-bob-x">
                <img src={"./images/shapes/benefits-one-shape-1.png"} alt=""/>
            </div>
            <div className="benefits-one__shape-2 float-bob-y">
                <img src={"./images/shapes/benefits-one-shape-2.png"} alt=""/>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-xl-5">
                        <div className="benefits-one__left">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">Our Benefits</span>
                                <h2 className="section-title__title">We deliver the highest facilities.</h2>
                            </div>
                            <p className="benefits-one__text">We provide a stunning home and offices with every upgrade and
                                features. We help our customers to take full advantage of Government schemes.</p>
                        </div>
                    </div>
                    <div className="col-xl-7">
                        <div className="benefits-one__right">
                            <div className="row">
                                <div className="col-xl-6 col-lg-6">
                                    <div className="benefits-one__single">
                                        <div className="benefits-one__content">
                                            <div className="benefits-one__icon">
                                                <span className="icon-travel-insu1"><i className='fas fa-shield-alt'></i></span>
                                            </div>
                                            <div className="benefits-one__title">
                                                <h3><a href="about.html">CCTV Camera <br/> security</a></h3>
                                            </div>
                                        </div>
                                        <div className="benefits-one__arrow">
                                            <a href="about.html"><i className="fas fa-angle-right"></i></a>
                                        </div>
                                    </div>
                                    <div className="benefits-one__single">
                                        <div className="benefits-one__content">
                                            <div className="benefits-one__icon">
                                                <span className="icon"><i className='fas fa-fire-extinguisher'></i></span>
                                            </div>
                                            <div className="benefits-one__title">
                                                <h3><a href="about.html">Fire <br/> safety</a></h3>
                                            </div>
                                        </div>
                                        <div className="benefits-one__arrow">
                                            <a href="about.html"><i className="fas fa-angle-right"></i></a>
                                        </div>
                                    </div>
                                    <div className="benefits-one__single">
                                        <div className="benefits-one__content">
                                            <div className="benefits-one__icon">
                                                <span className="icon-plan"><i className='fas fa-car-side'></i></span>
                                            </div>
                                            <div className="benefits-one__title">
                                                <h3><a href="about.html">Parking <br/>Facilities</a></h3>
                                            </div>
                                        </div>
                                        <div className="benefits-one__arrow">
                                            <a href="about.html"><i className="fas fa-angle-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 col-lg-6">
                                    <div className="benefits-one__single">
                                        <div className="benefits-one__content">
                                            <div className="benefits-one__icon">
                                                <span className="icon-plan"><i className='fas fa-solar-panel'></i></span>
                                            </div>
                                            <div className="benefits-one__title">
                                                <h3><a href="about.html">Solar water <br/> System</a></h3>
                                            </div>
                                        </div>
                                        <div className="benefits-one__arrow">
                                            <a href="about.html"><i className="fas fa-angle-right"></i></a>
                                        </div>
                                    </div>
                                    <div className="benefits-one__single">
                                        <div className="benefits-one__content">
                                            <div className="benefits-one__icon">
                                                <span className="icon-glob"><i className='fas fa-water'></i></span>
                                            </div>
                                            <div className="benefits-one__title">
                                                <h3><a href="about.html">RCC Tank <br/> </a></h3>
                                            </div>
                                        </div>
                                        <div className="benefits-one__arrow">
                                            <a href="about.html"><i className="fas fa-angle-right"></i></a>
                                        </div>
                                    </div>
                                    <div className="benefits-one__single">
                                        <div className="benefits-one__content">
                                            <div className="benefits-one__icon">
                                                <span className="icon-fligh"><i className='fas fa-tint'></i></span>
                                            </div>
                                            <div className="benefits-one__title">
                                                <h3><a href="about.html"> 24/7 water <br/></a></h3>
                                            </div>
                                        </div>
                                        <div className="benefits-one__arrow">
                                            <a href="about.html"><i className="fas fa-angle-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
		</>
	);
}

export default Benefits;
