const Footer = () => {
	return (
		<>
		  <footer className="site-footer">
            <div className="container">
                <div className="site-footer__inner">
                    <div className="site-footer__shape-1 zoom-fade-3">
                        <img src={"./images/shapes/site-footer-shape-1.png"} alt=""/>
                    </div>
                    <div className="site-footer__top">
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="100ms">
                                <div className="footer-widget__column footer-widget__about">
                                    <div className="footer-widget__logo">
                                        <a href="#"><img src={"./images/favicons/logo-w.png"} alt="" style={{maxWidth: "200px"}}/></a>
                                    </div>
                                    <div className="footer-widget__about-text-box">
                                        <p className="footer-widget__about-text">Our traditional business model is <br/>based on the accomplishment of 
                                            <br/>large projects (Industrial, Residential, Commercial) in India.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms">
                                <div className="footer-widget__column footer-widget__Contact">
                                    <div className="footer-widget__title-box">
                                        <h3 className="footer-widget__title">Address</h3>
                                    </div>
                                    <ul className="footer-widget__Contact-list list-unstyled">
                                        <li>
                                            <div className="icon">
                                                <span className="fas fa-map-marker"></span>
                                            </div>
                                            <div className="text">
                                                <b style={{color: "#fff"}}>Gurukrupa Developers</b>
                                                <p>RK Park Street No-1, Atmiya Complex, Near Astha Gate, 150 fit Ring
                                                    Road, Rajkot-360004, Gujrat, India.</p>
                                            </div>
                                            <div className="text">

                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="400ms">
                                <div className="footer-widget__column footer-widget__Contact">
                                    <div className="footer-widget__title-box">
                                        <h3 className="footer-widget__title">Contact</h3>
                                    </div>
                                    <ul className="footer-widget__Contact-list list-unstyled">
                                      
                                        <li>
                                            <div className="icon">
                                                <span className="fas fa-envelope"></span>
                                            </div>
                                            <div className="text">
                                                <p><a href="mailto:gurukrupabuildersrajkot@gmail.com">gurukrupabuildersrajkot@gmail.com</a>
                                                </p>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-xl-2 col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="300ms">
                                <div className="footer-widget__column footer-widget__links">
                                    <div className="footer-widget__title-box">
                                        <h3 className="footer-widget__title">Follow Us</h3>
                                    </div>
                                    <div className="site-footer__bottom-right">
                                        <div className="site-footer__social">
                                            <a href="https://www.facebook.com/Gurukrupadevelopersrajkot/" target="_blank" className="fab fa-facebook-square" rel="noreferrer"></a>
                                            <a href="https://www.instagram.com/gurukrupa__builders/" target="_blank" className="fab fa-instagram" rel="noreferrer"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="site-footer__bottom">
                        <div className="site-footer__bottom-inner">
                            <div className="site-footer__bottom-left">
                                <p className="site-footer__bottom-text">© 2022 Gurukrupa Builders, all rights reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
		</>
	);
}

export default Footer;
