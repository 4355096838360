const Catalogue = () => {
	return (
		<>
		  <section className="cta-one" id="news">
            <div className="cta-one__bg-shape" style={{backgroundImage: "url(./images/shapes/cta-one-bg-shape.png)"}}>
            </div>
            <div className="container">
                <div className="cta-one__content">
                    <div className="cta-one__title">
                        <h3>Shyam Sanidhya Project Catalogue</h3>
                    </div>
                    <div className="cta-one__btn-box">
                        <a href="./images/shyam.pdf" target="_blank" className="thm-btn cta-one__btn">Download</a>
                    </div>
                </div>
            </div>
        </section>
		</>
	);
}

export default Catalogue;
