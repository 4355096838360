const Blog = () => {
	return (
		<>
		  <section className="faq-one" style={{backgroundColor: "#ffffff"}}>
		    <div className="container">
                <div className="row">
                    <div className="col-xl-6 col-lg-6">
                        <div className="faq-one__left">
                            <div className="faq-one__img wow slideInLeft animated" data-wow-delay="100ms" data-wow-duration="2500ms" style={{visibility: "visible", animationDuration: "2500ms", animationDelay: "100ms", animationName: "slideInLeft"}}>
                                <img src={"./images/advantage.jpg"} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="faq-one__right">
                            <div className="section-title text-left">
                                <span className="section-title__tagline">Advantages</span>
                                <h2 className="section-title__title">Our Competitive Advantages</h2>
                            </div>
                            <div className="accrodion-grp faq-one-accrodion" data-grp-name="faq-one-accrodion">
                                <div className="accrodion">
                                    <div className="accrodion-title">
                                        <h4><i className="fas fa-arrow-right"></i>  &nbsp;  Innovation in product</h4>
                                    </div>                                 
                                </div>
                                <div className="accrodion">
                                    <div className="accrodion-title">
                                        <h4><i className="fas fa-arrow-right"></i>  &nbsp; Value for Money  </h4>
                                    </div>
                                </div>
                                <div className="accrodion last-chiled">
                                    <div className="accrodion-title">
                                        <h4><i className="fas fa-arrow-right"></i>  &nbsp; Superior design, styling and quality</h4>
                                    </div>
                                </div>
                                <div className="accrodion last-chiled">
                                    <div className="accrodion-title">
                                        <h4><i className="fas fa-arrow-right"></i>  &nbsp; Support-We are not satisfied until you are satisfied.</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
		</>
	);
}

export default Blog;
